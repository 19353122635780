export function event(name, props = {}) {
  if (typeof window !== 'undefined') {
    window.gtag('event', name, { ...props});
    if(name == 'Signup'){
      window.snaptr('track', 'SIGN_UP');
    }
    if(name == 'Login'){
      window.snaptr('track', 'LOGIN');
    }
  }
}
