/* eslint-disable react/prop-types */
import React from 'react';

const AfterAndBeforeItem = ({
  title,
  quote,
  name,
  image,
  isActive = false,
  home = false,
  lang = 'ar',
}) =>
  lang == 'en' ? (
    <div className={`carousel-item ${isActive ? 'active' : ''}`}>
      <div className="heros">
        <div className="row">
          <div className="col-md-6 order-2">
            <div className="content">
              <p className="bold cl-p">{title}</p>
              <p className={home ? 'head-p-sec-home' : 'head-p-sec'}>
                “{quote}“
              </p>
              <div className="user">
                <p className="bold" id="user-Name">
                  {name}
                  <img
                    loading="lazy"
                    className="stories-logoplus"
                    src={'./assets/images/logoplus.svg'}
                    alt=""
                  />
                </p>
                <p>ElCoach Plus member</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="profile">
              <img loading="lazy" src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={`carousel-item ${isActive ? 'active' : ''}`}>
      <div className="heros">
        <div className="row">
          <div className="col-md-6 order-2">
            <div className="content">
              <p className="bold cl-p">{title}</p>
              <p className={home ? 'head-p-sec-home' : 'head-p-sec'}>
                “{quote}“
              </p>
              <div className="user">
                <p className="bold" id="user-Name">
                  {name}
                  <img
                    loading="lazy"
                    className="stories-logoplus"
                    src={'./assets/images/logoplus.svg'}
                    alt=""
                  />
                </p>
                <p>مشترك في الكوتش بلس</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="profile">
              <img loading="lazy" src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

export default function AfterAndBefore({
  home = false,
  lang = 'ar',
  hideMen = false,
  hideWomen = false,
}) {
  return lang == 'en' ? (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-ride="carousel"
    >
      <div className="carousel-inner">
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="Sherif Kamel"
            quote="When I seriously decided to lose weight, I tried ElCoach and it really helped me fall back in love with sports because it started with me gradually and even when I didn't find time to go the gym"
            title="Lost 10 kg and 13% body fat"
            image="https://cdn.elcoach.me/assets/beforeandafter/Sherif_Kamel.png"
            isActive
            home={home}
            lang={'en'}
          />
        )}
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="Yahya Ahmed"
            quote="I had trouble moving. I installed ElCoach randomly then decided to give it a shot and I was thrilled with how the workout difficulty matched my very limited fitness level, the diverse nutrition, and that helped me stay committed to my goal and my health improved significantly."
            title="Lost 18 kg and 10% body fat"
            image="https://cdn.elcoach.me/assets/beforeandafter/Yahya_Ahmed.png"
            home={home}
            lang={'en'}
          />
        )}
        {hideWomen ? null : (
          <AfterAndBeforeItem
            name="Nesma Mohamed"
            quote="I lost 12 kg with ElCoach untill now, this was the most committed I have stayed on a diet and this was mainly because of the help of ElCoach team whom I could reach through the app and get constant support and motivation"
            title="Lost 12 kg and 10% body fat"
            image="https://cdn.elcoach.me/assets/beforeandafter/Nesma_Mohamed.png"
            home={home}
            lang={'en'}
            isActive={hideMen}
          />
        )}
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="Mostafa Wafaa"
            quote="My experience with ElCoach was different than any time I commited to working out, my body fat percentage decreased 5% and my muscle mass increased significantly through staying consistent with my nutrition and workout plan"
            title="Lost 5% body fat"
            image="https://cdn.elcoach.me/assets/beforeandafter/Mostafa_Wafa.png"
            home={home}
            lang={'en'}
          />
        )}
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="Ahmed Nadi"
            quote="With ElCoach's home workouts and nutrition plan I was surprisingly able to lose 3-4 kg a week and I increased my fitness and became capable of doing more than 35 reps of push ups after I had very limited fitness level"
            title="Lost 16 kg and 6% body fat"
            image="https://cdn.elcoach.me/assets/beforeandafter/Ahmed_Nady.png"
            home={home}
            lang={'en'}
          />
        )}
        {hideWomen ? null : (
          <AfterAndBeforeItem
            name="Gawzaa"
            quote="ElCoach really helped me figure out what exactly I need to do every day in a simple and organized way to to achieve my goal."
            title="Lost 10 kg and 7% body fat"
            image="https://cdn.elcoach.me/assets/beforeandafter/Gawza2.png"
            home={home}
            lang={'en'}
          />
        )}
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="Eslam Jahin"
            quote="I really enjoyed gym and home workouts, and also the nutrition, there were a wide variety of easy-made meals. Thank God, I was  able to reach my ideal weight with ElCoach"
            title="Lost 16 kg and 6% body fat"
            image="https://cdn.elcoach.me/assets/beforeandafter/Islam_Gahen.png"
            home={home}
            lang={'en'}
          />
        )}
        {hideWomen ? null : (
          <AfterAndBeforeItem
            name="Rana Ismael"
            quote="I tried a lot committing to just workout but I hadn't been reaching any significant result. Then I tried committing to daily workout and nutrition with ElCoach and for the first time reached the result that I wanted and my body got in the best shape. Thanks ElCoach!"
            title="Lost 7 kg and 6% body fat"
            image="https://cdn.elcoach.me/assets/beforeandafter/Rana_Ismael.png"
            home={home}
            lang={'en'}
          />
        )}
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="Mohamed Yassen"
            quote="ElCoach’s workout and nutrition programs helped me a lot, through it I was able to see a significant change after a lot of failed attempts and this gave me motivation to be consistent."
            title="Lost 21 kg and 15% body fat"
            image="https://cdn.elcoach.me/assets/beforeandafter/Mohamed_Yassen.png"
            home={home}
            lang={'en'}
          />
        )}
      </div>
      <a
        className={
          home
            ? 'stories-prev-home carousel-control-prev'
            : 'stories-prev carousel-control-prev'
        }
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g fill="none" fillRule="evenodd">
            <g fill="#FFF">
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M16.71,12.96C16.9,12.77,17,12.52,17,12.25v-0.51c0-0.27-0.11-0.52-0.29-0.71L9.85,4.2
                                                    C9.73,4.07,9.56,4,9.38,4S9.03,4.07,8.9,4.2L7.96,5.14c-0.12,0.12-0.2,0.29-0.2,0.47c0,0.18,0.07,0.34,0.2,0.47L13.89,12
                                                    l-5.94,5.92c-0.13,0.12-0.2,0.3-0.2,0.47c0,0.18,0.07,0.35,0.2,0.47L8.9,19.8C9.03,19.93,9.2,20,9.38,20s0.35-0.07,0.47-0.2
                                                    L16.71,12.96z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </a>
      <a
        className={
          home
            ? 'stories-next-home carousel-control-next'
            : 'stories-next carousel-control-next'
        }
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g fill="#ffffff" fillRule="evenodd">
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M15.947 11.04c.188.187.293.441.293.707v.506c-.003.265-.108.518-.293.707l-6.856 6.843c-.125.126-.295.197-.473.197s-.349-.071-.474-.197l-.947-.947c-.125-.123-.196-.291-.196-.467 0-.176.07-.344.196-.467L13.133 12 7.197 6.078C7.071 5.953 7 5.782 7 5.604c0-.177.071-.348.197-.473l.947-.934C8.27 4.071 8.44 4 8.618 4s.348.071.473.197l6.856 6.843z"
                          transform="translate(-850 -3014) translate(-93 2243) translate(553 144) translate(358 611) translate(32 12) matrix(-1 0 0 1 24 4)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </a>
    </div>
  ) : (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-ride="carousel"
    >
      <div className="carousel-inner">
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="Sherif Kamel"
            quote="لما قررت أخس بحد جربت الكوتش وحقيقي ساعدني جدًا أرجع أحب الرياضة لأنه أبتدي معايا بالتدريج وحتى لما مكنتش بلاقي وقت للجيم كنت بحول للتمارين المنزلية وقدرت أوصل معاهم لنتيجة مرضية جًدا"
            title="خسارة 10 كيلو و 13% دهون"
            image="https://cdn.elcoach.me/assets/beforeandafter/Sherif_Kamel.png"
            isActive
            home={home}
          />
        )}
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="Yahya Ahmed"
            quote="كنت بتحرك بصعوبة. حملت الكوتش بالصدفة وقررت أجربه وانبسطت جدًا من مناسبة مستوى التمرين للياقتي المحدودة، وتنوع التغذية، وده ساعدني أستمر في هدفي، وزادت نسبة العضل، وتحسن صحتي جدًا"
            title="خسارة 18 كيلو و6% دهون"
            image="https://cdn.elcoach.me/assets/beforeandafter/Yahya_Ahmed.png"
            home={home}
          />
        )}
        {hideWomen ? null : (
          <AfterAndBeforeItem
            name="Nesma Mohamed"
            quote="خسيت مع الكوتش حوالي 12 كيلو لحد دلوقتي في أكتر مرة أستمريت فيها على نظام دايت وده بمساعدة فريق الكوتش اللي كنت بقدر أتواصل معاهم من خلال الأبليكيشن واخد منهم دايمًا التشجيع والدعم"
            title="خسارة 12 كيلو و 10% دهون"
            image="https://cdn.elcoach.me/assets/beforeandafter/Nesma_Mohamed.png"
            home={home}
            isActive={hideMen}
          />
        )}
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="Mostafa Wafa"
            quote="تجربتي مع الكوتش كانت مختلفة اكتر من اي فترة انتظمت فيها في التدريب ، نسبة الدهون نزلت ٥٪ و الكتلة العضلية زادت بشكل ملحوظ بالالتزام بالنظام الغذائي مع جدول التمرين"
            title="خسارة %5 دهون"
            image="https://cdn.elcoach.me/assets/beforeandafter/Mostafa_Wafa.png"
            home={home}
          />
        )}
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="أحمد نادي"
            quote="مع التمارين المنزلية وتغذية الكوتش خسرت من 3-4 كيلو أسبوعيًا بشكل غير متوقع خاصة ورفعت لياقتي وبقيت أعمل أكثر من 35 عدة ضغط بعد ما كانت لياقتي وقوتي ضعيفة جدًا "
            title="خسارة 16 كيلو و 6% دهون"
            image="https://cdn.elcoach.me/assets/beforeandafter/Ahmed_Nady.png"
            home={home}
          />
        )}
        {hideWomen ? null : (
          <AfterAndBeforeItem
            name="جوزاء"
            quote="الكوتش حقيقي ساعدني أعرف أنا محتاجة أعمل ليه كل يوم بشكل منظم وسهل علشان أحقق هدفي واستمتعت كثيرًا بتنوع التمارين وقدرتها على تقوية الجسم وحرق الدهون "
            title="خسارة 10 كيلو، 7% دهون"
            image="https://cdn.elcoach.me/assets/beforeandafter/Gawza2.png"
            home={home}
          />
        )}
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="اسلام جاهين"
            quote="أستمتعت جدًا بتمارين المنزل والجيم والتغذية مع الكوتش خاصة أن الأطباق اليومية سهل تحضيرها ومتعارف عليها. الحمد لله وصلت لوزني مثالي وبرشح الكوتش دايمًا لأصدقائي لأنه فعلًا حل متكامل"
            title="خسارة 16 كيلو و 6% دهون"
            image="https://cdn.elcoach.me/assets/beforeandafter/Islam_Gahen.png"
            home={home}
          />
        )}
        {hideWomen ? null : (
          <AfterAndBeforeItem
            name="رنا اسماعيل"
            quote="جربت كتير التزم بالتمارين فقط بس مكنتش بوصل لنتيجة قوية. جربت مع الكوتش التزم بالتمارين والتغذية اليومية ووصلت لنتيجة اللي أنا كان نفسي فيها ورجع جسمي أفضل من الأول. شكرًا للكوتش"
            title="خسارة 7 كيلو،  6% دهون"
            image="https://cdn.elcoach.me/assets/beforeandafter/Rana_Ismael.png"
            home={home}
          />
        )}
        {hideMen ? null : (
          <AfterAndBeforeItem
            name="محمد ياسين"
            quote="التمارين والتغذية مع الكوتش ساعدتني جدًا جدًا وشوفت من خلالها تغيير ملحوظ وحقيقي بعد ما حاولت كتير قبل كدة وده اداني شغف اواظب"
            title="خسارة 21 كيلو ، 15% دهون"
            image="https://cdn.elcoach.me/assets/beforeandafter/Mohamed_Yassen.png"
            home={home}
          />
        )}
      </div>
      <a
        className={
          home
            ? 'stories-prev-home carousel-control-prev'
            : 'stories-prev carousel-control-prev'
        }
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g fill="none" fillRule="evenodd">
            <g fill="#FFF">
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M16.71,12.96C16.9,12.77,17,12.52,17,12.25v-0.51c0-0.27-0.11-0.52-0.29-0.71L9.85,4.2
                                                    C9.73,4.07,9.56,4,9.38,4S9.03,4.07,8.9,4.2L7.96,5.14c-0.12,0.12-0.2,0.29-0.2,0.47c0,0.18,0.07,0.34,0.2,0.47L13.89,12
                                                    l-5.94,5.92c-0.13,0.12-0.2,0.3-0.2,0.47c0,0.18,0.07,0.35,0.2,0.47L8.9,19.8C9.03,19.93,9.2,20,9.38,20s0.35-0.07,0.47-0.2
                                                    L16.71,12.96z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </a>
      <a
        className={
          home
            ? 'stories-next-home carousel-control-next'
            : 'stories-next carousel-control-next'
        }
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g fill="#ffffff" fillRule="evenodd">
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M15.947 11.04c.188.187.293.441.293.707v.506c-.003.265-.108.518-.293.707l-6.856 6.843c-.125.126-.295.197-.473.197s-.349-.071-.474-.197l-.947-.947c-.125-.123-.196-.291-.196-.467 0-.176.07-.344.196-.467L13.133 12 7.197 6.078C7.071 5.953 7 5.782 7 5.604c0-.177.071-.348.197-.473l.947-.934C8.27 4.071 8.44 4 8.618 4s.348.071.473.197l6.856 6.843z"
                          transform="translate(-850 -3014) translate(-93 2243) translate(553 144) translate(358 611) translate(32 12) matrix(-1 0 0 1 24 4)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </a>
    </div>
  );
}
